.home {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .system-header {
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        border: 1px solid rgba(215, 215, 215);

        // margin: 30px;
        .header-left {
            min-width: 200px;
            max-width: 200px;
            margin-right: 20px;
            border-right: 1px solid rgba(215, 215, 215);
            // border-bottom: 2px solid red;
            display: flex;
            justify-content: center;
            align-items: center;

            .task-system {
                text-wrap: nowrap;
                margin: 20px;
                margin-bottom: 20px;
                font-size: large
            }
        }

        .header-right {
            width: 100%;
            display: flex;
            position: relative;
            align-items: center;

            .arco-btn {
                position: absolute;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 90px;
                height: 35px;
                border-radius: 5px;
                font-size: 15px;
                background: white;
                color: rgb(20, 150, 219);
                border: 1px solid rgb(20, 150, 219);
            }

            .show {
                left: 50px;
            }

            .createTask {
                right: 30px;
            }
            .userName{
                margin-left: 30%;
                span{
                    font-size: 16px;
                    font-weight: 700;
                }
            }

            .showToken {
                width: 500px;
                position: absolute;
                left: 200px;
                display: flex;

                .checker {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .checkerToken {
                        width: 50px;
                    }
                }

                .checkerId {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .checkerName {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }


            // .task-notice{
            //     margin-right: 20px;
            //     .notice-logo{
            //         position: relative;
            //         width: 30px;
            //         height: 30px;
            //         .notice-svg{
            //             max-width: 100%;
            //             max-height: 100%;
            //         }
            //         .notice-number{
            //             position: absolute;
            //             right: 0;
            //             bottom: 20px;
            //             span{
            //                 font-size: 14px;
            //                 color: rgb(20,150,219);
            //             }
            //         }
            //         .message-list{
            //             .no-bullet {
            //                 list-style-type: none;
            //                 >li{
            //                     display: flex;
            //                     align-items: center;
            //                     justify-content: center;
            //                     height: 30px;
            //                     font-size: 15px;
            //                     border: 1px solid grey;
            //                     .no-underline{
            //                         text-decoration: none;
            //                         color: rgb(20,150,219);
            //                     }
            //                 }
            //               }
            //             position: absolute;
            //             width: 290px;
            //             top: 29px;
            //             right: 0;
            //             z-index: 102; /* 较高的z-index值，使其显示在最上层 */
            //             background-color: ghostwhite;
            //         }
            //     }
            // }
            // .task-search{
            //     width: 100px;
            //     height: 20px;
            //     .arco-input-group-wrapper{
            //         width: 100%;
            //         height: 100%;
            //         .arco-input-group{
            //             .arco-input-group-addafter{
            //                 .arco-btn{
            //                     background-color: rgba(215,215,215);
            //                 }
            //             }
            //         }
            //     }
            // }
        }
    }
}

.error_message {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    overflow: hidden;
    color: coral;
    padding: 10px;
}